import * as MXTS from "@maxxton/cms-mxts-api";

import { BaseOptions, LocalizedBaseOptions } from "./form.types";
import { FormSpec, SomeInputSpec, localized } from "../../form-specs";
import { FormWidgetSpec, Widget } from "../";
import { getBaseOptionsSpecs, localizedOptions } from "./";
import { getFormFieldVariable, getFormFieldsDescription, isFormFieldRequired } from "./form.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";
import { getMemo, readOnlyField } from "../../components/utils";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { WidgetGroup } from "../widget.enum";
import { getMxtsEnv } from "../../plugins/mxts";
import { globalApiContext } from "../../containers/CmsProvider";
import namespaceList from "../../i18n/namespaceList";

interface LocalizedWidgetOptions extends LocalizedBaseOptions {
    placeholder: string;
}

interface WidgetOptions extends BaseOptions<LocalizedWidgetOptions> {
    enableReadOnly?: boolean;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-textarea-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "textareaWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "textareaWidgetOptions"),
    /* jscpd:ignore-start */
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getBaseOptionsSpecs("textarea"),
                            {
                                type: "checkbox",
                                variable: "useforReservationMemo",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "useforReservationMemo"),
                                default: false,
                                visible: (options: any) => options.useforBookingsModule,
                            },
                            {
                                variable: "memoCategory",
                                type: "asyncSelect",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "memoCategory"),
                                async optionList() {
                                    return getMemo(globalApiContext());
                                },
                                visible: (options: any) => options.useforReservationMemo && options.useforBookingsModule,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "placeholder",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "placeholder"),
                                        type: "text",
                                    },
                                ],
                            }),
                            readOnlyField("enableReadOnly"),
                        ],
                    ],
                },
            ],
        },
    ],
    /* jscpd:ignore-end */
};

export const textareaWidget: FormWidgetSpec<WidgetOptions> = {
    id: "form-textarea",
    type: "form",
    widgetGroup: WidgetGroup ? WidgetGroup.INPUT : 6,
    name: getI18nLocaleObject(namespaceList.pluginForm, "textareaWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "textareaWidgetDesc"),
    /* jscpd:ignore-start */
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        fieldId: "",
        required: false,
        localized: [],
        dynamicFieldId: "",
        baseUrlSiteId: undefined,
        useforBookingsModule: false,
        useforReservationMemo: false,
        enableReadOnly: false,
    }),
    /* jscpd:ignore-end */
    async toInputSpec(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<SomeInputSpec<any, string>> {
        const { useforReservationMemo, enableReadOnly } = widget.options;
        const localeOptions = localizedOptions(context, widget.options) || { label: "", default: "" };
        let variable: string = getFormFieldVariable(widget.options) || new Date().getTime() + "";
        let memoCategory: string | undefined = widget.options.memoCategory;
        if (useforReservationMemo) {
            if (!memoCategory) {
                const env = await getMxtsEnv(context, context.currentLocale.code);
                const memoCategories = await MXTS.MxtsApi.getMemoCategories(env, { size: 2000 }).then((categories) => categories.content);
                memoCategory = memoCategories
                    .filter((cat: any) => cat.internetMemo)
                    .map((id: any) => id.categoryId)
                    .toString();
            }
            variable = `__RESERVATION__MEMO__${variable}-${memoCategory}`;
        }
        return {
            type: "textarea",
            variable,
            label: localeOptions.label,
            default: localeOptions.default,
            placeholder: (localeOptions as LocalizedWidgetOptions).placeholder,
            required: await isFormFieldRequired(widget.options, context),
            message: (localeOptions as LocalizedWidgetOptions).message,
            options: widget.options,
            enableReadOnly,
        };
    },
    instanceDescription({ widget }): string {
        const formFrieldDescriptionParams = {
            /* jscpd:ignore-start */
            fieldId: widget.options.fieldId,
            dynamicFieldId: widget.options.dynamicFieldId,
            useforBookingsModule: widget.options.useforBookingsModule,
            defaultDescription: getI18nLocaleString(namespaceList.pluginForm, "textareaWidgetDesc"),
            /* jscpd:ignore-end */
        };
        return getFormFieldsDescription(formFrieldDescriptionParams);
    },
};
